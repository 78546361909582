define('@fortawesome/free-brands-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faFacebookF = {
    prefix: 'fab',
    iconName: 'facebook-f',
    icon: [320, 512, [], "f39e", "M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"]
  };
  var faXTwitter = {
    prefix: 'fab',
    iconName: 'x-twitter',
    icon: [512, 512, [], "e61b", "M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"]
  };

  exports.faFacebookF = faFacebookF;
  exports.faXTwitter = faXTwitter;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
